import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'

import pageWithSideMenu from '../../containers/pageWithSideMenu'

import CardHeader from '../../containers/CardHeader'
import CardSectionTitle from '../../containers/CardSectionTitle';

import apisAPICall from '../../app/apiCalls/apisAPICall'
import { apis_dashboard } from "../../app/apiEndpoints"

import { FaEdit, FaPlus } from "react-icons/fa";
import { Card, Col, Row, Accordion, Button } from 'react-bootstrap'
import pageContent from '../../containers/pageContent';
import Select from "../../containers/Form/Select";
import { ResponsiveBar } from '@nivo/bar'
import DataTable from '../../containers/DataTable';
import { tableTypes } from '../../app/settings';

import { selectAPI } from '../../app/store/actions/apiActions'

const APISection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Cascade API Access";

    const selectedClientID = useSelector((state) => state.currentAPIID)
    const [clientID, setClientID] = useState(selectedClientID)
    const [apis, setApis] = useState([])
    const [alerts, setAlerts] = useState([])
    const [data, setData] = useState([])

    const [client, setClient] = useState(null)
    const [copied, setCopied] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const headerAction = isSysadmin ? {'path': "/api/add", 'icon':FaPlus} : {}
    const editAction = isSysadmin ? {'path': `/api/${clientID}`, 'icon':FaEdit} : {}

    useMemo(() => {
        const getDetails = async () => {
            const data = await apisAPICall(apis_dashboard, {'clientID': clientID, 'requester': userID, 'company': companyID })
            if(data.apis) setApis(data.apis)
            if(data.alerts) setAlerts(data.alerts)
            if(data.data) setData(data.data)
            if(clientID > 0) {
                const foundObject = data.apis.find(item => parseInt(item.ID) === parseInt(clientID));
                console.log("found= ", foundObject)
                setClient(foundObject ?? null)
            }
        }
        getDetails()
    }, [clientID, userID, companyID])

    const selectClient = (e) => {
        setClientID(e.target.value)
        props.selectAPI(parseInt(e.target.value))
        if(clientID === "0") {
            setClient(null)
        }
        else {
            setClient(apis[clientID] ?? null)
        }
        setCopied(false)
    }

    const tableData = () => {
        if(alerts.length === 0) {
            return [];
        } 
        let data = [];
        alerts.forEach(alert => {
            data.push({'id': alert.ID, 'values': [
                alert.endpoint,
                alert.type,
                alert.message,
                alert.date
            ]});
        });
        return data
    }

    return (
        <>
        <Row>
            <Col md={7}>
                <Card>
                    <CardHeader title={title} description="Daily API Stats" />

                    <div style={{ height: 400 }}>
                        <ResponsiveBar
                    data={data}
                    keys={[
                        'rejected',
                        'unprocessed',
                        'processed',
                        'completed',
                    ]}
                    indexBy="date"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'paired' }}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Country',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Value',
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemsSpacing: 2,
                                    symbolSize: 20,
                                    itemDirection: 'left-to-right'
                                }
                            ]}
                            animate={true}
                            motionConfig="gentle"
                        />
                    </div>

                    <CardHeader title={clientID > 0 ? "API Logs" : "API Alerts"} description={`API ${clientID > 0 ? "Logs" : "Alerts"} from last 24 hours`} />
                    <DataTable hover={true} tableID="alert" fieldNames={["Endpoint", "Alert Type", "Alert Message", "Date"]} dataRows={tableData()} pagination={false} tableSettings={tableTypes.None} hideTotal={true} />
                </Card>
            </Col>
            <Col md={5}>
                <Card>
                    <CardHeader title="API Clients" description="Select to Filter" action={headerAction} />
                    <Row className="justify-content-md-center">
                        <Col md={8} className="text-center">
                            <Select
                                value={clientID}
                                formValues={apis}
                                name="clientID"
                                useDefault={true}
                                onChange={selectClient}
                                validate="numeric|required"
                                useColumns={false}
                            />
                        </Col>
                    </Row>
                </Card>
                    { client &&
        <Card>
            <CardHeader title={client.NAME} description="API Details" action={editAction} />
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="Client Details" />
                        <Card.Body>
                            <strong>Client Key (Subpid):</strong><br/>
                            {client.company_key} 
                            <p className="mt-3"><strong>Pay Rate:</strong><br/>
                            ${client.pay_rate}</p>
                            <p className="mt-3"><strong>Postback URL:</strong><br/>
                            {client.postback_url.replaceAll("&amp;","&")}</p>
                            
                            
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="API Details" />
                        <Card.Body>
                            <strong>API Key:</strong> <br/>
                            <Row>
                                <Col md={9}>{client.api_key}</Col>
                                <Col md={3}>
                                    {copied ? <small className="text-success">Copied!</small> : <Button id="copyBtn" className='btn-sm' onClick={() => {navigator.clipboard.writeText(client.api_key); setCopied(true) }}>Copy</Button>}
                                </Col>
                            </Row>
                            
                            <p className="mt-3"><strong>Offer IDs:</strong>
                            {client.offers && client.offers.map((row, i) => ( 
                                <span key={`offer_${i}`}>
                                <br/>{row.offer_name}: {row.offer_id}
                                </span>)
                            )}</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Reports" />
                        <Card.Body>
                        <p>
                                <a href={`/api/postbacks/${clientID}`} title="Postback Logs">Postback Logs</a>
                                </p>
                                <hr/>
                            {client.offers && client.offers.map((row, i) => ( 
                                <p key={`report_${i}`}>
                                <a href={`/api/reports/${clientID}/${row.offer_id}`} title="Report">{row.offer_name} Report</a>
                                </p>)
                            )}
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Admin" />
                        <Card.Body>
                            {client.offers && client.offers.map((row, i) => ( 
                                <p key={`rules_${i}`}>
                                <a href={`/api/rules/${clientID}/${row.offer_id}`} title="Rules">{row.offer_name} Rules</a>
                                </p>)
                            )}
                            <a href={`/api/documentation/${clientID}`} title="Documentation">Documentation</a>
                        </Card.Body>
                    </Card>
                </Accordion>
            </Card.Body>
        </Card>
                    }

            </Col>
        </Row>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectAPI: (apiID) => { 
            dispatch(selectAPI(apiID))
        }
    }
  }

const ConnectedAPISection = connect(
    state => ({ ...state }),
    mapDispatchToProps
  )(APISection);
  
  export default pageWithSideMenu(pageContent(ConnectedAPISection), "APIs");