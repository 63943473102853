import DashboardSection from "../components/Dashboard/DashboardSection";
import Contact from "../components/Contact";
import Profile from "../components/Profile/Profile";
import Settings from "../components/Profile/Settings";

import ProjectSection from "../components/Projects/ProjectSection";
import ProjectForm from "../components/Projects/ProjectForm";

import APISection from "../components/APIs/APISection";
import APIForm from "../components/APIs/APIForm";

import BannerSection from "../components/Banners/BannerSection";
import BannerView from "../components/Banners/BannerView";
import BannerForm from "../components/Banners/BannerForm";

import LanderSection from "../components/Landers/LanderSection";

import ClientSection from "../components/Clients/ClientSection";
import ClientForm from "../components/Clients/ClientForm";

import InvoiceSection from "../components/Invoices/InvoiceSection"
import InvoicePrint from "../components/Invoices/InvoicePrint";
import InvoiceForm from "../components/Invoices/InvoiceForm";

import ReportSection from "../components/Reports/ReportSection";
import ReportView from "../components/Reports/ReportView";
import ReportForm from "../components/Reports/ReportForm";

import UsersSection from "../components/Users/UsersSection";
import UsersForm from "../components/Users/UsersForm";

import ToolsSection from "../components/Tools/ToolsSection";
import ToolsAdmin from "../components/Tools/ToolsAdmin";
import ToolsForm from "../components/Tools/ToolsForm";
import TJPauseTool from "../components/Tools/Unique/TJPauseTool";
import TJBulkBidTool from "../components/Tools/Unique/TJBulkBidTool";
import CamModelTool from "../components/Tools/Unique/CamModelTool";
import CamModelLinkTool from "../components/Tools/Unique/CamModelLinkTool";
import CamModelLinkToolExoclick from "../components/Tools/Unique/CamModelLinkToolExoclick";
import TJBidSchedulerTool from "../components/Tools/Unique/TJBidSchedulerTool";
import ReportDownload from "../components/Reports/ReportDownload";
import MCLTwitterTool from "../components/Tools/Unique/MCLTwitterTool";
import SubpidTool from "../components/Tools/Unique/SubpidTool";
import LanderDashboard from "../components/Tools/Unique/LanderDashboard";
import SubpidExclusionTool from "../components/Tools/Unique/SubpidExclusionTool";
import SubpidCapTool from "../components/Tools/Unique/SubpidCapTool";
import DashboardView from "../components/Dashboard/DashboardView";
import MediaBuyDashboard from "../components/MediaBuy/MediaBuyDashboard";
import MediaBuyAPIView from "../components/MediaBuy/MediaBuyAPIView";
import MediaBuyPlacementView from "../components/MediaBuy/MediaBuyPlacementView";
import AdminSection from "../components/Admin/AdminSection";
import MediaBuyDataUpload from "../components/MediaBuy/MediaBuyDataUpload";
import BannerSiteForm from "../components/Banners/BannerSiteForm";
import BannerAdvertiserView from "../components/Banners/BannerAdvertiserView";
import SubpidFallbackTool from "../components/Tools/Unique/SubpidFallbackTool";
import XMLSection from "../components/XML/XMLSection";
import XMLList from "../components/XML/XMLList";
import XMLImageList from "../components/XML/XMLImageList";
import XMLForm from "../components/XML/XMLForm";
import CampaignOptimizerTool from "../components/Tools/Unique/CampaignOptimizerTool";
import CampaignOptimizerLog from "../components/Tools/Unique/CampaignOptimizerLog";
import LanderView from "../components/Landers/LanderView";
import PathsSection from "../components/Paths/PathsSection";
import PathsListing from "../components/Paths/PathsListing";
import PrivacySection from "../components/Privacy/PrivacySection";
import APIDocumentation from "../components/APIs/APIDocumentation";
import APIRules from "../components/APIs/APIRules";
import APIPostbackReport from "../components/APIs/APIPostbackReport";
import APIReport from "../components/APIs/APIReport";

export const routes = [
    {
      path: "/Contact",
      component: Contact
    },
    {
      path: "/Profile",
      component: Profile
    },
    {
      path: "/Settings",
      component: Settings
    },
    {
      path: "/projects/:projectID",
      component: ProjectForm
    },
    {
      path: "/projects",
      component: ProjectSection
    },
    {
      path: "/api/rules/:clientID/:offerID",
      component: APIRules
    },
    {
      path: "/api/documentation/:apiID",
      component: APIDocumentation
    },
    {
      path: "/api/reports/:clientID/:offerID",
      component: APIReport
    },
    {
      path: "/api/postbacks/:apiID",
      component: APIPostbackReport
    },
    {
      path: "/api/:apiID",
      component: APIForm
    },
    {
      path: "/api",
      component: APISection
    },
    {
      path: "/banners/add",
      component: BannerSiteForm
    },
    {
      path: "/banners/:siteURI/edit",
      component: BannerSiteForm
    },
    {
      path: "/banners/:siteURI/add",
      component: BannerForm
    },
    {
      path: "/banners/:siteURI/edit/:bannerID",
      component: BannerForm
    },
    {
      path: "/banners/:siteURI/:companyURI",
      component: BannerView
    },
    {
      path: "/banners/:siteURI",
      component: BannerAdvertiserView
    },
    {
      path: "/banners",
      component: BannerSection,
    },
    {
      path: "/landers/view/:landerID",
      component: BannerView
    },
    {
      path: "/landers/:lpID",
      component: LanderView
    },
    {
      path: "/landers",
      component: LanderSection,
    },
    {
      path: "/paths/:pathType",
      component: PathsListing,
    },
    {
      path: "/paths",
      component: PathsSection,
    },
    {
      path: "/privacy",
      component: PrivacySection,
    },
    {
      path: "/tools/view/tj-bulk-bids",
      component: TJBulkBidTool
    },
    {
      path: "/tools/view/tj-bulk-pause",
      component: TJPauseTool
    },
    {
      path: "/tools/view/tj-bids",
      component: TJBidSchedulerTool
    },
    {
      path: "/tools/view/mcl-tweets",
      component: MCLTwitterTool
    },
    {
      path: "/tools/view/cam-models",
      component: CamModelTool
    },
    {
      path: "/tools/view/cams-campaign-links",
      component: CamModelLinkTool
    },
    {
      path: "/tools/view/cams-campaign-links-exoclick",
      component: CamModelLinkToolExoclick
    },
    {
      path: "/tools/view/subpids",
      component: SubpidTool
    },
    {
      path: "/tools/view/lp-dashboard",
      component: LanderDashboard
    },
    {
      path: "/tools/view/trans-lp-dashboard",
      component: LanderDashboard
    },
    {
      path: "/tools/view/subpid-exclusions",
      component: SubpidExclusionTool
    },
    {
      path: "/tools/view/subpid-caps",
      component: SubpidCapTool
    },
    {
      path: "/tools/view/subpid-fallbacks",
      component: SubpidFallbackTool
    },
    {
      path: "/tools/view/campaign-optimizer/:campaignID",
      component: CampaignOptimizerLog
    },
    {
      path: "/tools/view/campaign-optimizer",
      component: CampaignOptimizerTool
    },
    {
      path: "/tools/view/:toolURI",
      component: ToolsAdmin
    },
    {
      path: "/tools/:toolID",
      component: ToolsForm
    },
    {
      path: "/tools",
      component: ToolsSection
    },
    {
      path: "/reports/export/:reportURI",
      component: ReportDownload
    },
    {
      path: "/reports/view/:reportURI",
      component: ReportView
    },
    {
      path: "/reports/:reportID",
      component: ReportForm
    },
    {
      path: "/reports",
      component: ReportSection
    },
    {
      path: "/dashboard/:dashURI",
      component: DashboardView
    },
    {
      path: "/xml/images",
      component: XMLImageList
    },
    {
      path: "/xml/feeds/add",
      component: XMLForm
    },
    {
      path: "/xml/feeds/:xmlID",
      component: XMLForm
    },
    {
      path: "/xml/feeds",
      component: XMLList
    },
    {
      path: "/xml",
      component: XMLSection
    },
    {
      path: "/invoices/print/:invoiceID",
      component: InvoicePrint
    },
    {
      path: "/invoices/:invoiceID",
      component: InvoiceForm
    },
    {
      path: "/invoices",
      component: InvoiceSection
    },
    {
      path: "/clients/:clientID",
      component: ClientForm
    },
    {
      path: "/clients",
      component: ClientSection
    },
    {
      path: "/users/:userID",
      component: UsersForm
    },
    {
      path: "/users",
      component: UsersSection
    },
    {
      path: "/mediabuy/upload",
      component: MediaBuyDataUpload
    },
    {
      path: "/mediabuy/:apiURI/placement/:placementURI",
      component: MediaBuyPlacementView
    },
    {
      path: "/mediabuy/:apiURI",
      component: MediaBuyAPIView
    },
    {
      path: "/mediabuy",
      component: MediaBuyDashboard
    },
    {
      path: "/admin",
      component: AdminSection
    },
    {
      path: "/",
      component: DashboardSection
    }
  ];