import React from 'react';

import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'

import CardHeader from '../../containers/CardHeader'

import { Breadcrumb, Card } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'
import PrivacyTable from './PrivacyTable';
import pageContentWithDetails from '../../containers/pageContentWithDetails';
import PrivacyDetails from './PrivacyDetails';

const PrivacySection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    if(!isSysadmin) return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item active>Privacy Requests</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title="Privacy Requests" description="Listing of all Privacy Requests" />
            <PrivacyTable showDetailsOnClick={true}  />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContentWithDetails(PrivacySection, PrivacyDetails), "Privacy Requests"));
