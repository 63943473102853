import React from 'react';

import { useState } from 'react'
import { CSVLink } from "react-csv";
import { Row, Col, Form, FormGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../containers/Form/Button';

export default function ReportOptions(props) {
    const [startDate, setStartDate] = useState(new Date(props.startDate))
    const [endDate, setEndDate] = useState(new Date(props.endDate))
    const [reportSource, setReportSource] = useState(props.source)
    const [cascadeType, setCascadeType] = useState(props.cascadeType)
    const [reportID] = useState(props.reportID)

    const cascadeReports = [25,26,30,35,42]

    console.log("type = " + reportID)
    return (
        <form className="form-horizontal" id="reportForm" method="GET" action={props.formAction}>
            <FormGroup>
                <Row>
                    <Col md={1}>&nbsp;</Col>
                { cascadeReports.includes(parseInt(reportID)) &&
                    <Col md={2}>
                        <Form.Label>Cascade Type</Form.Label>
                        <select id="cascadeType" name="cascadeType" className="form-control" value={cascadeType} onChange={(e) => setCascadeType(e.target.value)}>
                            <option value="1">Straight</option>
                            <option value="2">Trans</option>
                            <option value="6">Gay</option>
                            <option value="3">Cams</option>
                            <option value="4">Trans Cams</option>
                            <option value="5">Blacklist</option>
                        </select>
                    </Col> }
            { (props.reportID === "2" || props.reportID === "10") &&
                    <Col md={2}>
                        <Form.Label>Source</Form.Label>
                        <select id="source" name="source" className="form-control" value={reportSource} onChange={(e) => setReportSource(e.target.value)}>
                            <option value="1">Banners</option>
                            <option value="2">Popups</option>
                        </select>
                    </Col> }
            { (props.reportID === "9" || props.reportID === "13") &&
                    <Col md={2}>
                        <Form.Label>Group By</Form.Label>
                        <select id="source" name="source" className="form-control" value={reportSource} onChange={(e) => setReportSource(e.target.value)}>
                            <option value="1">Campaign</option>
                            <option value="2">Campaign - Banner</option>
                        </select>
                    </Col> }
            { parseInt(props.hasDateRange) === 1 ?
                <>
                    <Col md={2}>
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker id="startDate" name="startDate" selected={startDate} className="form-control" onChange={(date) => setStartDate(date)} />
                    </Col>
                    <Col md={2}>
                        <Form.Label>End Date</Form.Label>
                        <DatePicker id="endDate" name="endDate" selected={endDate} className="form-control" onChange={(date) => setEndDate(date)} />                    
                    </Col>
                    <Col md={5} id="form-btn">
                        <br/>
                        <Button className="primary" title="Generate Report" />&nbsp;
                        <CSVLink 
                            data={props.csvData}
                            filename={props.csvFileName}
                            className="btn btn-secondary"
                            target="_blank">Export Report</CSVLink>
                    </Col>
                </> : 
                <>
                <Col md={5} id="form-btn">
                    <br/>
                    <CSVLink 
                        data={props.csvData}
                        filename={props.csvFileName}
                        className="btn btn-secondary"
                        target="_blank">Export Report</CSVLink>
                </Col>
                </> }
                </Row>
            </FormGroup>
        </form>
    )
}