import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import CardHeader from '../../containers/CardHeader';
import CardSectionTitle from '../../containers/CardSectionTitle';

import privacyAPICall from '../../app/apiCalls/privacyAPICall'
import { privacy_complete, privacy_details } from "../../app/apiEndpoints"
import { Card, Accordion, Row, Col, Button } from 'react-bootstrap';
import ContentNotFound from '../../containers/ContentNotFound';

const PrivacyDetails = (props) => {
    const [request, setRequest] = useState({})
    const privacyID = useSelector((state) => state.currentPrivacyID)
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    useMemo(() => {
        const getTool = async () => {
            if(privacyID > 0) {
                const data = await privacyAPICall(privacy_details, {'privacyID': privacyID, 'requester': userID, 'company': companyID })
                setRequest(data.privacy)
            }
        }
        getTool()
    }, [privacyID, userID, companyID])

    const completeRequest = async () => {
        const updateData = await privacyAPICall(privacy_complete, { 'requester': userID, 'company': companyID, 'privacyID': privacyID })
        if(updateData.privacy) { 
            setRequest(updateData.privacy)
        }
    }

    if(!privacyID) return <ContentNotFound />

    return (
        <Card className='mt-5'>
            <CardHeader title={request.name} description={`${request.name}'s Request for ${request.request_type}`} />
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="Request" />
                        <Card.Body>
                            <Row>
                                <Col md={4}>Date</Col>  
                                <Col md={8}>{request.request_date}</Col>    
                            </Row>
                            <Row>
                                <Col md={4}>Type</Col>  
                                <Col md={8}>{request.request_type}</Col>    
                            </Row>
                            <Row>
                                <Col md={4}>Domain</Col>  
                                <Col md={8}>{request.domain}</Col>    
                            </Row>
                            <Row>
                                <Col md={4}>Verified?</Col>  
                                <Col md={8}>{request.verified === '1' ? `Yes, ${request.verified_date}` : 'No'}</Col>    
                            </Row>
                            <Row>
                                <Col md={4}>Expired?</Col>  
                                <Col md={8}>{request.expired === '1' ? 'Yes' : `No, ${request.expire_date}`}</Col>    
                            </Row>
                            <Row>
                                <Col md={4}>Completed?</Col>  
                                <Col md={8}>{request.request_completed === '1' ? `Yes, ${request.completion_date}` : 'No'}</Col>    
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Details" />
                        <Card.Body>
                            <Row>
                                <Col md={12}><strong>Request Details</strong></Col>
                            </Row>
                            <Row>
                                <Col md={12}>{request.details}</Col>
                            </Row>
                            {request.request_completed === '1' &&
                            <>
                            <Row className="mt-3">
                                <Col md={12}><strong>Completion Details</strong></Col>
                            </Row>
                            <Row>
                                <Col md={12}>{request.completion_notes}</Col>
                            </Row>
                            </>
                            }

                        </Card.Body>
                    </Card>
                    {request.request_completed !== '1' && request.verified === '1' &&
                    <Card>
                        <CardSectionTitle title="Admin" />
                        <Card.Body>
                            <Button onClick={completeRequest()}>Mark as Completed</Button>
                        </Card.Body>
                    </Card>
                    }
                </Accordion>
            </Card.Body>
        </Card>
    )
}

export default connect(state => ({...state}))(PrivacyDetails)