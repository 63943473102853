import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import { Breadcrumb, Card } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'
import PathsTable from './PathsTable';

const PathsListing = (props) => {
    let { pathType } = useParams();
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const pathName = () => {
        var str = pathType ?? ''
        return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') + ' Paths';
      }
    

    if(!isSysadmin) return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/paths/">Paths</Breadcrumb.Item>
          <Breadcrumb.Item active>{pathName()}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <PathsTable showDetailsOnClick={true} cascadeType={pathType} title={pathName()} description={`Listing of current ${pathName()} paths`} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(PathsListing), "Paths"));
