import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import privacyAPICall from '../../app/apiCalls/privacyAPICall'
import { selectPrivacy } from '../../app/store/actions/privacyActions'
import { privacy_list } from '../../app/apiEndpoints'

const PrivacyTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "request"
    const tableFields = ["Requester", "Type", "Verified", "Expired", "Completed", "Date"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentPrivacyID)

    const onClick = (e) => {
        if(!props.showDetailsOnClick) return;
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        props.selectPrivacy(rowID)
    }

    useMemo(() => {
        const getData = async () => {
            const data = await privacyAPICall(privacy_list, {'requester': userID, 'company': companyID })
            setData(data.privacy)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.id, 'values': [
                (props.showDetailsOnClick ? `<b>${row.name}</b><br>${row.email}` : `<b><a href="/privacy/${row.id}">${row.name}</a></b><br>${row.email}`),
                row.request_type,
                row.verified,
                row.expired,
                row.request_completed,
                row.request_date
            ]});
        });
        return rows
    }


    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={rowID} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectPrivacy: (id) => { 
            dispatch(selectPrivacy(id))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(PrivacyTable)