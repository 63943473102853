import React from 'react';

import { connect } from 'react-redux'
//import { useHistory } from 'react-router-dom';


import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'
import InvalidPermissions from '../../containers/InvalidPermissions';
import landerAPICall from '../../app/apiCalls/landerAPICall';
import { lander_details } from '../../app/apiEndpoints';
import DataLoading from '../../containers/DataLoading';
import ContentNotFound from '../../containers/ContentNotFound';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';


const LanderView = (props) => {
    let { lpID } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)


    const [status, setStatus] = useState(0)
    const [stats, setStats] = useState([])
    const [userStats, setUserStats] = useState([])
    const [stepkeys, setStepkeys] = useState([])
    const [lander, setLander] = useState([])

    useEffect(() => {
        const getData = async () => {
            setStatus(1);
            try {
                const data = await landerAPICall(lander_details, {
                    requester: userID,
                    company: companyID,
                    lpID: lpID,
                });
                setStats(data.stats);
                setUserStats(data.userStats);
                setStepkeys(data.stepkeys);
                setLander(data.lander);
                setStatus(2);
            } catch (error) {
                console.error('Error fetching data:', error);
                setStatus(-1); // Optional: Set an error status
            }
        };
    
        if (status === 0) {
            getData();
        }
    }, [lpID, userID, companyID, status]);
    

    if(!isSysadmin) return <InvalidPermissions />

    if(status !== 2) return <DataLoading contentType={"Landing Page"} />
    else if(status === 2 && (!stats || stats.length === 0)) return <ContentNotFound />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/landers/">Landing Pages</Breadcrumb.Item>
          <Breadcrumb.Item active>LP #{lpID}: {lander.lander}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                    <h4 className="title mb-3" id="">Lander Stats</h4>
                <Row>
                    <Col md={6}>Success to Affiliate</Col>
                    <Col md={3}>{stats.AFFILIATE ?? 0}</Col>
                    <Col md={3}>{stats.AFFILIATE_RATE ?? 0}</Col>
                </Row>
                <Row className="pb-3">
                    <Col md={6}>Success to Redirect</Col>
                    <Col md={3}>{stats.REDIRECT ?? 0}</Col>
                    <Col md={3}>{stats.REDIRECT_RATE ?? 0}</Col>
                </Row>
                <Row>
                    <Col md={12} style={{ borderTop: '1px solid black' }} className='pt-2 pb-2'></Col>
                </Row>
                <Row>
                    <Col md={6}>Total Success</Col>
                    <Col md={3}>{stats.SUCCESS ?? 0}</Col>
                    <Col md={3}>{stats.SUCCESS_RATE ?? 0}</Col>
                </Row>
                <Row className="pb-3">
                    <Col md={6}>Failed</Col>
                    <Col md={3}>{stats.FAILED ?? 0}</Col>
                    <Col md={3}>{stats.FAILED_RATE ?? 0}</Col>
                </Row>
                <Row>
                    <Col md={12} style={{ borderTop: '1px solid black' }} className='pt-2 pb-2'></Col>
                </Row>
                <Row>
                    <Col md={6}>Total Registrations</Col>
                    <Col md={3}>{stats.TOTAL ?? 0}</Col>
                </Row>

            { userStats && userStats.length > 0 ?
            <>
                <h4 className="title mb-3 mt-5" id="">User Stats</h4>
                        <Row>
                            <Col md={4}>
                                <strong>Platform</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Affiliate</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Redirect</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Failed</strong>
                            </Col>
                            <Col md={2}>
                            <strong>Total</strong>
                            </Col>
                        </Row>
                    {userStats.map((stat,key) => (
                        <Row key={key}>
                            <Col md={4}>
                                {stat.platform}
                            </Col>
                            <Col md={2}>
                                {stat.AFFILIATE}
                            </Col>
                            <Col md={2}>
                                {stat.REDIRECT}
                            </Col>
                            <Col md={2}>
                                {stat.FAILED}
                            </Col>
                            <Col md={2}>
                                {stat.TOTAL}
                            </Col>
                        </Row>
                    ))}
                    </> : '' 
                }
                    </Col>
                    <Col md={1}>&nbsp;</Col>
                    <Col md={5}>
                    <h4 className="title mb-3" id="">Lander Steps</h4>
            { stepkeys && stepkeys.length > 0 ?
                    stepkeys.map((step,s) => (
                        <Row key={step.name}>
                            <Col md={2}>
                                {step.step}
                            </Col>
                            <Col md={10}>
                                {step.name}
                            </Col>
                        </Row>
                    )) : '' 
                }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(LanderView), "Lander Data"));
