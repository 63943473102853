import React from 'react';
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';

import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'
import DataTable from '../../containers/DataTable'

import apisAPICall from '../../app/apiCalls/apisAPICall'
import { apis_report } from '../../app/apiEndpoints'
import { Card, Breadcrumb } from 'react-bootstrap'

const APIReport = (props) => {
    let { clientID } = useParams();
    let { offerID } = useParams();
    const [client, setClient] = useState('')
    const [offer, setOffer] = useState('')
    const [data, setData] = useState([])

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const title = "API Report";
    const subtitle = `${client} API Registration History for ${offer} Offer`
    const tableID = "pb"
    const tableFields = ["Date", "Tracking ID1", "Tracking ID2", "Email", "Username", "Gender", "Country", "Birthdate", "IP Address", "Lead Accepted?", "User Sent to URL?", "Successful Postback?", "Revenue"
     ]


    useMemo(() => {
        const getAPIs = async () => {
            const data = await apisAPICall(apis_report, {'requester': userID, 'company': companyID, 'clientID': clientID, 'offerID': offerID })
            setData(data.data)
            if(data.client) setClient(data.client)
            if(data.offer) setOffer(data.offer)
        }
        getAPIs()
    }, [clientID, offerID, userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.log_id, 'values': [
                row.reg_date,
                row.api_tracking_id1,
                row.api_tracking_id2,
                row.email,
                row.username,
                row.gemder,
                row.country,
                row.birthdate,
                row.ipAddress,
                row.lead_accepted === "1" ? "Yes" : "No",
                row.user_sent_to_url === "1" ? "Yes" : "No",
                row.successful_postback === "1" ? "Yes" : "No",
                row.traffic_cost
            ]});
        });
        return rows
    }

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/api/">API Cascade Access</Breadcrumb.Item>
          <Breadcrumb.Item active>{offer} Report</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} />
            <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(APIReport), "APIs"));