import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import landerAPICall from '../../app/apiCalls/landerAPICall'
import { landers_list } from '../../app/apiEndpoints'
import { tableTypes } from '../../app/settings';
import ActiveTableToggle from '../../containers/ActiveTableToggle';

const LanderTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "lp"
    const tableFields = ["", "ID", "Name", "URL", "Active", "Privacy Policy", "Push Notifications", "Second Offer", "Back Button"]
    const [displayView, setDisplayView] = useState(1)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentBannerID)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    useMemo(() => {
        const getData = async () => {
            const data = await landerAPICall(landers_list, {'requester': userID, 'company': companyID })
            setData(data.landers)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            if(displayView === 1 && row.isactive === "0") { return }
            rows.push({'id': row.lpid, 'values': [
                (row.imgFile ? `<img src="https://c84c135c09.mjedge.net/cdnimages/screenshots/lps/${row.imgFile}" style="height:10rem" />` : ''),
                row.lpid,
                (props.showDetailsOnClick ? row.lander : `<a href="/landers/${row.lpid}">${row.lander}</a>`),
                `<a href="https://${row.host_domain}/lps/${row.lpid}/" target="_blank">https://${row.host_domain}/lps/${row.lpid}/</a>`,
                row.isactive,
                row.show_privacy,
                row.push_notifications,
                row.second_offer,
                row.back_button,
                `${isSysadmin ? `<a href="/landers/${row.lpid}/" class="btn btn-primary btn-sm">Data</a>` : ''}`
            ]});
        });
        return rows
    }

    const filterData = (e) => {
        let { value } = e.target;
        setDisplayView(parseInt(value))
    }

    return (
        <>
            <ActiveTableToggle title="Landing Pages" description="Listing of current Landing Pages" value={displayView} onChange={filterData} />
            <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} currentRowID={rowID} tableSettings={tableTypes.None} />
        </>
    )
}


export default connect(state => ({...state}))(LanderTable)