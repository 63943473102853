import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import Select from './Form/Select';

export default function ActiveTableToggle(props) {

    return (
        <Card.Header>
            <Row>
                <Col md={8}>
                    <h4 className="title">{props.title}</h4>
                    {props.description && <p className="category">{props.description}</p> }
                </Col>
                <Col md={4} className="text-right">
                    <Select
                        value={props.value}
                        label="Display"
                        name="displayView"
                        formValues={[{ID: 1, NAME: "Active Only"}, {ID: 0, NAME: "Show All"},]}
                        useDefault={false}
                        onChange={props.onChange}
                        validate="numeric|required"
                        colSize="6"
                    />      
                </Col>
            </Row>
        </Card.Header>
    );
}
