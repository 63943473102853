import React from 'react';
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';

import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'
import DataTable from '../../containers/DataTable'

import apisAPICall from '../../app/apiCalls/apisAPICall'
import { apis_postbacks } from '../../app/apiEndpoints'
import { Card, Breadcrumb } from 'react-bootstrap'

const APIPostbackReport = (props) => {
    let { apiID } = useParams();
    const [client, setClient] = useState('')
    const [postbacks, setPostbacks] = useState([])

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const title = "API Postback Logs";
    const subtitle = `Postback history for ${client}`
    const tableID = "pb"
    const tableFields = ["Date", "Offer ID", "Offer Type", "URL", "Method", "Payload", "Response Code", "Response", "Success", "Amount" ]


    useMemo(() => {
        const getAPIs = async () => {
            const data = await apisAPICall(apis_postbacks, {'requester': userID, 'company': companyID, 'apiID': apiID })
            setPostbacks(data.postbacks)
            if(data.client) setClient(data.client)
        }
        getAPIs()
    }, [apiID, userID, companyID])

    const tableData = () => {
        if(postbacks.length === 0) {
            return [];
        } 
        let data = [];
        postbacks.forEach(pb => {
            data.push({'id': pb.log_id, 'values': [
                pb.log_date,
                pb.offer_id,
                pb.offer_name,
                pb.request_url,
                pb.request_method,
                pb.request_payload,
                pb.response_code,
                pb.response_body,
                pb.success === "1" ? "Yes" : "No",
                pb.amount
            ]});
        });
        return data
    }

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/api/">Cascade API Access</Breadcrumb.Item>
          <Breadcrumb.Item active>{client} Postback Logs</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title={title} description={subtitle} />
            <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(APIPostbackReport), "APIs"));