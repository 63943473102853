import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import Form from '../../containers/Form/Form'
import Button from '../../containers/Form/Button'

import apisAPICall from '../../app/apiCalls/apisAPICall'
import { api_rules, api_rule_save } from "../../app/apiEndpoints"
import { Card, Row, Col, Breadcrumb } from 'react-bootstrap'
import ContentNotFound from '../../containers/ContentNotFound';

import Select from 'react-select';

const APIRules = (props) => {
    let { clientID, offerID } = useParams();

    const [api, setApi] = useState({})
    const [pathRules, setPathRules] = useState([])
    const [geoRules, setGeoRules] = useState([])
    const [deviceRules, setDeviceRules] = useState([])
    const [platformRules, setPlatformRules] = useState([])
    const [browserRules, setBrowserRules] = useState([])
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [geos, setGeos] = useState([])
    const [pathOptions, setPathOptions] = useState([])

    const deviceTypes = [{'label': 'Desktop', 'value': 'desktop'}, {'label': 'Mobile', 'value': 'mobile'}]
    const platforms = [{'label': 'Android', 'value': 'Android'}, {'label': 'iOS', 'value': 'iOS'}, {'label': 'Mac OS X', 'value': 'Mac OS X'}, {'label': 'Windows', 'value': 'Windows'}, {'label': 'Linux', 'value': 'Linux'}, {'label': 'Other', 'value': 'Other'}]
    const browsers = [{'label': 'Chrome', 'value': 'Chrome'}, {'label': 'Firefox', 'value': 'Firefox'}, {'label': 'Safari', 'value': 'Safari'}, {'label': 'Microsoft', 'value': 'Microsoft'}, {'label': 'Other', 'value': 'Other'}]

    useEffect(() => {
        const getDetails = async () => {
            if(offerID > 0) {
                const data = await apisAPICall(api_rules, {'clientID': clientID, 'offerID': offerID, 'requester': userID, 'company': companyID })
                if(data.api) setApi(data.api)
                if(data.geos) setGeos(data.geos)
                if(data.paths) setPathOptions(data.paths)

                if(data.pathRules) setPathRules(data.pathRules)
                if(data.geoRules) setGeoRules(data.geoRules)
                if(data.deviceRules) setDeviceRules(data.deviceRules)
                if(data.platformRules) setPlatformRules(data.platformRules)
                if(data.browserRules) setBrowserRules(data.browserRules)
            }
        }
        getDetails()
    }, [clientID, offerID, userID, companyID])

    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)

            const data = await apisAPICall(api_rule_save, { 
                'clientID': clientID, 
                'offerID': offerID, 
                'pathRules': pathRules.map(option => option.value), 
                'geoRules': geoRules.map(option => option.value), 
                'deviceRules': deviceRules.map(option => option.value), 
                'platformRules': platformRules.map(option => option.value), 
                'browserRules': browserRules.map(option => option.value), 
                'requester': userID })
            if(data.error) {
                setFormMessage(data.error)
            } 
            else {
                if(data.saved) {
                    setFormMessage("Rule saved")
                }
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if( updating && !processing) {
            performUpdate()
        }
    }, [clientID, offerID, pathRules, geoRules, deviceRules, platformRules, browserRules, updating, processing, userID])

    const updateExclusion = (e) => {
        e.preventDefault()
        setFormMessage("")


        setUpdating(true)
    }


    if(!api) return <ContentNotFound />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/api/">Cascade API Access</Breadcrumb.Item>
          <Breadcrumb.Item active>{api.offer_name} Rules</Breadcrumb.Item>
        </Breadcrumb>
            <Form title={`Manage ${api.offer_name} Rules`} description={`Current ${api.offer_name} Rules being used on Cascade API for ${api.company_name}`} onSubmit={updateExclusion}> 
        <Row>
            <Col md={12}> 
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col md={10} className="control-label col-form-label text-left pt-5"><b>Paths (have been rejected by...)</b></Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <Select
                                        isMulti
                                        name="pathRules"
                                        options={pathOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={pathRules}
                                        onChange={(selected) => setPathRules(selected || [])} 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} className="control-label col-form-label text-left pt-5"><b>Geos</b></Col>
                            </Row>
                            <Row >
                                <Col md={10} sm={8}>
                                    <Select
                                        isMulti
                                        name="geoRules"
                                        options={geos}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={geoRules}
                                        onChange={(selected) => setGeoRules(selected || [])} 
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={10} className="control-label col-form-label text-left pt-5"><b>Devices</b></Col>
                            </Row>
                            <Row>
                                <Col md={10} sm={8}>
                                    <Select
                                        isMulti
                                        name="deviceRules"
                                        options={deviceTypes}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={deviceRules}
                                        onChange={(selected) => setDeviceRules(selected || [])} 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} className="control-label col-form-label text-left pt-5"><b>Operating System</b></Col>
                            </Row>
                            <Row>
                                <Col md={10} sm={8}>
                                    <Select
                                        isMulti
                                        name="platformRules"
                                        options={platforms}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={platformRules}
                                        onChange={(selected) => setPlatformRules(selected || [])} 
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} className="control-label col-form-label text-left pt-5"><b>Browsers</b></Col>
                            </Row>
                            <Row>
                                <Col md={10} sm={8}>
                                    <Select
                                        isMulti
                                        name="browserRules"
                                        options={browsers}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={browserRules}
                                        onChange={(selected) => setBrowserRules(selected || [])} 
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10} className="text-center pt-5">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title="Save Rules" className="primary" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        </Form>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(APIRules), "API Exclusion Rules"));