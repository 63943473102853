import React from 'react';

import { connect, useSelector, useDispatch } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";

import apisAPICall from '../../app/apiCalls/apisAPICall'

import { selectAPI } from '../../app/store/actions/apiActions'

import { SITE_NAME } from '../../app/globals'
import { api_details, api_save } from '../../app/apiEndpoints';
import InvalidPermissions from '../../containers/InvalidPermissions';
import ContentNotFound from '../../containers/ContentNotFound';
import { Breadcrumb, Card } from 'react-bootstrap';
import CardHeader from '../../containers/CardHeader';
import DataTable from '../../containers/DataTable';
import { tableTypes } from '../../app/settings';

const APIForm = (props) => {
    let { apiID } = useParams();

    const [api, setApi] = useState({})
    const [payHistory, setPayHistory] = useState([])
    const [inputValue, setInputValue] = useState({  company_name: "", company_key: "", pay_rate: "", contact_name: "", contact_email: "", postback_url: "", spaceid: "", isActive: 1, offer1: "", offer2: "" });
    const [errors, setErrors] = useState({ company_name: "", company_key: "", pay_rate: "", contact_name: "", contact_email: "", postback_url: "", spaceid: "", isActive: "", offer1: "", offer2: "" });
    const { company_name, company_key, pay_rate, contact_name, contact_email, postback_url, spaceid, isActive, offer1, offer2 } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin
    const newAPI = !api.client_id

    const dispatch = useDispatch();

    useMemo(() => {
        const getAPI = async () => {
            if(parseInt(apiID) > 0) {
                const data = await apisAPICall(api_details, {'apiID': apiID, 'requester': userID, 'company': companyID })
                if(data.api.client_id) {
                    const values = { 
                        company_name: data.api.company_name, 
                        company_key: data.api.company_key, 
                        pay_rate: data.api.pay_rate, 
                        contact_name: data.api.contact_name, 
                        contact_email: data.api.contact_email, 
                        postback_url: data.api.postback_url.replaceAll("&amp;","&"),
                        spaceid: data.api.spaceid,
                        isActive: data.api.is_active,
                        offer1: data.api.offer1,
                        offer2: data.api.offer2
                    }
                    setInputValue(values)
                    setApi(data.api)
                    if(data.payhistory) setPayHistory(data.payhistory)
                    setLoading(false)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setApi({ missing: true })
                }
            }
        }
        if(parseInt(apiID) > 0 && (api === undefined || api === null || !api.client_id) && loading) {
            getAPI()
        } else if(apiID === "add") {
            setLoading(false)
        }
    }, [apiID, userID, companyID, api, loading])

    const saveAPI = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await apisAPICall(api_save, {
            'apiID': api.client_id ? api.client_id : 0, 
            'requester': userID, 
            'company_name': company_name, 
            'company_key': company_key, 
            'pay_rate': pay_rate, 
            'postback_url': postback_url,
            'spaceid': spaceid,
            'contact_name': contact_name, 
            'contact_email': contact_email, 
            'isActive': isActive,
            'offer1': offer1,
            'offer2': offer2
        })

        //if successful, delete from project object
        if(data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.api?.client_id) apiID = data.api.client_id
        setApi(data.api)
        
        console.log(props)
        setFormMessage("API has been saved")
        window.history.replaceState(null, SITE_NAME, `/api/${apiID}`);
        dispatch(selectAPI(apiID))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    const payData = () => {
        if(payHistory.length === 0) {
            return [];
        } 
        let data = [];
        payHistory.forEach(row => {
            data.push({'id': row.id, 'values': [
                '$' + row.pay_rate,
                row.modified_date
            ]});
        });
        return data
    }

    if(apiID === "add" && !isSysadmin)
        return <InvalidPermissions />


    if(api.missing)
        return <ContentNotFound contentType="API" />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/api/">Cascade API Access</Breadcrumb.Item>
          <Breadcrumb.Item active>{!newAPI ? "Edit API Access" : "Add API Access"}</Breadcrumb.Item>
        </Breadcrumb>
        <Form title={!newAPI ? "Edit API Access" : "Add API Access"}  description="" onSubmit={saveAPI} >
            { !loading ?
            <>
            <Input
                type="text"
                value={company_name}
                placeholder="Client Name"
                label="Client Name"
                name="company_name"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={company_key}
                placeholder="Client Key"
                label="Client Key (Subpid)"
                name="company_key"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="number"
                value={pay_rate}
                placeholder="Pay Rate"
                label="Pay Rate"
                name="pay_rate"
                onChange={handleChange}
                onError={handleError}
                validate="required|number"
                colSize="2"
                readOnly={!canEdit}
            />
            <Input
                type="number"
                value={spaceid}
                placeholder="GoAd Spaceid"
                label="Spaceid"
                name="spaceid"
                onChange={handleChange}
                onError={handleError}
                validate="required|number"
                colSize="2"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={contact_name}
                placeholder="Contact Name"
                label="Contact Name"
                name="contact_name"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={contact_email}
                placeholder="Contact Email"
                label="Contact Email"
                name="contact_email"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Select
                value={isActive}
                label="Active?"
                name="isActive"
                formField="yesno"
                onChange={handleChange}
                onError={handleError}
                validate="numeric"
                colSize="2"
                readOnly={!canEdit}
            />

        <h4>Postback URL</h4>
        <p>The following keys can be used: <br/>
            <small>&#123;tracking1&#125; <span className="pl-3">&#123;tracking2&#125;</span> <span className="pl-3">&#123;amount&#125;</span></small></p>
            <Input
                type="text"
                value={postback_url}
                placeholder="URL"
                label="Postback URL"
                name="postback_url"
                onChange={handleChange}
                onError={handleError}
                validate="url"
                colSize="6"
                readOnly={!canEdit}
            />

        <h4>API Offers</h4>
            <Select
                value={offer1}
                label="Main Cascade"
                name="offer1"
                formValues={[{ID: 0, NAME: "Disabled"}, {ID: 1, NAME: "Enabled"}]}
                useDefault={false}
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="2"
            />
            <Select
                value={offer2}
                label="Trans Cascade"
                name="offer2"
                formValues={[{ID: 0, NAME: "Disabled"}, {ID: 1, NAME: "Enabled"}]}
                useDefault={false}
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="2"
            />
            
            { canEdit &&
            <FormFooter 
                formButton={{title: "Save API", class: "primary"}} 
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        {payHistory && payHistory.length > 0 &&
        <Card>
            <CardHeader title="Pay Rate History" description="History of all pay rate changes for client" />
            <DataTable hover={true} tableID="pay" fieldNames={["Pay Rate", "Modified Date"]} dataRows={payData()} tableSettings={tableTypes.None} />
        </Card>
        }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectAPI: (apiID) => { 
            dispatch(selectAPI(apiID))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(pageWithSideMenu(pageContent(withRouter(APIForm)), "API Form"));
