import React, { useRef } from 'react'
import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';

import apisAPICall from '../../app/apiCalls/apisAPICall';
import { api_details } from '../../app/apiEndpoints';

import { useReactToPrint }  from "react-to-print";
import { APIDocumentationView } from './APIDocumentationView';

const APIDocumentation = () => {
    let { apiID } = useParams();
    const printRef = useRef()

    const [API, setAPI] = useState({})
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    useMemo(() => {
        const getAPI = async () => {
            if(apiID > 0) {
                const data = await apisAPICall(api_details, {'apiID': apiID, 'requester': userID, 'company': companyID })
                setAPI(data.api)

                //document.title = `FS API Documentation for ${data.api.company_name}`
            }
        }
        getAPI()
    }, [apiID, userID, companyID])

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });

    return (
        <div>
            {API && 
            <>
            <button onClick={handlePrint}>Print Documentation</button>
            <APIDocumentationView API={API} ref={printRef} />
            </> }
        </div>
    )
}

export default connect(state => ({...state}))(APIDocumentation);
