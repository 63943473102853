import React from 'react';

import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_subpid_list, tool_subpid_save } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col, FormControl, Breadcrumb } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'
import Select from '../../../containers/Form/Select'
import Input from '../../../containers/Form/Input'
import CascadeToolToggle from '../../../containers/CascadeToolToggle';

const SubpidTool = (props) => {
    const toolID = 9
    const [tool, setTool] = useState({})
    const [paths, setPaths] = useState(Array(10).fill(0))

    const [cascadeError, setCascadeError] = useState("")

    const [inputValue, setInputValue] = useState({ id: 0, routine: "", subpids: "", cascadeType: 0,
        order1: 1, weight1: 10, path1: '0', 
        order2: 0, weight2: 10, path2: '0', 
        order3: 0, weight3: 10, path3: '0', 
        order4: 0, weight4: 10, path4: '0', 
        order5: 0, weight5: 10, path5: '0', 
        order6: 0, weight6: 10, path6: '0', 
        order7: 0, weight7: 10, path7: '0', 
        order8: 0, weight8: 10, path8: '0', 
        order9: 0, weight9: 10, path9: '0', 
        order10: 0, weight10: 10, path10: '0' });
    const [errors, setErrors] = useState({ id: "", routine: "", subpids: "", cascadeType: "",
        order1: "", weight1: "", path1: "", 
        order2: "", weight2: "", path2: "", 
        order3: "", weight3: "", path3: "", 
        order4: "", weight4: "", path4: "", 
        order5: "", weight5: "", path5: "", 
        order6: "", weight6: "", path6: "", 
        order7: "", weight7: "", path7: "", 
        order8: "", weight8: "", path8: "", 
        order9: "", weight9: "", path9: "", 
        order10: "", weight10: "", path10: "" });
    const { id, routine, subpids, cascadeType,
        order1, weight1, path1,
        order2, weight2, path2, 
        order3, weight3, path3, 
        order4, weight4, path4, 
        order5, weight5, path5, 
        order6, weight6, path6, 
        order7, weight7, path7, 
        order8, weight8, path8, 
        order9, weight9, path9, 
        order10, weight10, path10 } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [pathOptions, setPathOptions] = useState([])
    const [routines, setRoutines] = useState([])

    const tableID = "result"
    const tableFields = ["Routine", "Paths", "Subpids", "Created", "Modified"]

    const orderOptions = [{'ID': 1, 'NAME': 1},{'ID': 2, 'NAME': 2},{'ID': 3, 'NAME': 3},{'ID': 4, 'NAME': 4},{'ID': 5, 'NAME': 5},{'ID': 6, 'NAME': 6}]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_subpid_list, { 'requester': userID, 'cascadeType': cascadeType })
                if(data.error) {
                    setCascadeError(data.error)
                } else {
                    setRoutines(data.routines)
                    setPathOptions(data.options)
                    setCascadeError("")
                }
            }
        }
        getDetails()
    }, [userID,cascadeType])


    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)
            const data = await toolsAPICall(tool_subpid_save, inputValue)
            if(data.error) {
                setFormMessage(data.error)
            } 
            else if(data.routines) {
                setRoutines(data.routines)
                setFormMessage("Routine Updates complete")
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if((routine).length > 0 && updating && !processing) {
            performUpdate()
        }
    }, [routines, id, routine,inputValue, subpids, updating, processing])

    const tableData = () => {
        if(routines.length === 0) {
            return [];
        } 
        //console.log('data', campaigns)
        let rows = [];
        routines.forEach(row => {
            rows.push({'id': `${row.routineid}`, 'values': [
                row.routine,
                row.pathstr,
                row.subpidstr,
                row.create_date,
                row.modified_date
            ]});
        });
        return rows
    }

    const viewRoutine = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        //look for the subpid in array
        let r = routines.find((element) => {
            return element.routineid === rowID;
          })
        if(r === undefined) {
            return
        }
        
        setInputValue({ id: r['routineid'], routine: r['routine'], subpids: r['subpids'], cascadeType: r['cascadeType'],
            order1: 1, weight1: 10, path1: '0', 
            order2: 0, weight2: 10, path2: '0', 
            order3: 0, weight3: 10, path3: '0', 
            order4: 0, weight4: 10, path4: '0', 
            order5: 0, weight5: 10, path5: '0', 
            order6: 0, weight6: 10, path6: '0', 
            order7: 0, weight7: 10, path7: '0', 
            order8: 0, weight8: 10, path8: '0', 
            order9: 0, weight9: 10, path9: '0', 
            order10: 0, weight10: 10, path10: '0' })

        if(r['paths'].length > 0) {
            r['paths'].forEach((row, rid) => {
                if(rid === 0) { 
                    setInputValue((prev) => ({
                        ...prev,
                        path1: row.pathid,
                        order1: row.order,
                        weight1: row.weight,
                      }));
                }
                else if(rid === 1) {
                    setInputValue((prev) => ({
                        ...prev,
                        path2: row.pathid,
                        order2: row.order,
                        weight2: row.weight,
                      }));
                } 
                else if(rid === 2) {
                    setInputValue((prev) => ({
                        ...prev,
                        path3: row.pathid,
                        order3: row.order,
                        weight3: row.weight,
                      }));
                } 
                else if(rid === 3) {
                    setInputValue((prev) => ({
                        ...prev,
                        path4: row.pathid,
                        order4: row.order,
                        weight4: row.weight,
                      }));
                } 
                else if(rid === 4) {
                    setInputValue((prev) => ({
                        ...prev,
                        path5: row.pathid,
                        order5: row.order,
                        weight5: row.weight,
                      }));
                } 
                else if(rid === 5) {
                    setInputValue((prev) => ({
                        ...prev,
                        path6: row.pathid,
                        order6: row.order,
                        weight6: row.weight,
                      }));
                } 
                else if(rid === 6) {
                    setInputValue((prev) => ({
                        ...prev,
                        path7: row.pathid,
                        order7: row.order,
                        weight7: row.weight,
                      }));
                } 
                else if(rid === 7) {
                    setInputValue((prev) => ({
                        ...prev,
                        path8: row.pathid,
                        order8: row.order,
                        weight8: row.weight,
                      }));
                } 
                else if(rid === 8) {
                    setInputValue((prev) => ({
                        ...prev,
                        path9: row.pathid,
                        order9: row.order,
                        weight9: row.weight,
                      }));
                } 
                else if(rid === 9) {
                    setInputValue((prev) => ({
                        ...prev,
                        path10: row.pathid,
                        order10: row.order,
                        weight10: row.weight,
                      }));
                } 
            });
            setPaths(paths)
        }
    }

    const addRoutine = (e) => {
        setInputValue({ id: 0, routine: "", subpids: "", cascadeType: cascadeType,
            order1: 1, weight1: 10, path1: '0', 
            order2: 0, weight2: 10, path2: '0', 
            order3: 0, weight3: 10, path3: '0', 
            order4: 0, weight4: 10, path4: '0', 
            order5: 0, weight5: 10, path5: '0', 
            order6: 0, weight6: 10, path6: '0', 
            order7: 0, weight7: 10, path7: '0', 
            order8: 0, weight8: 10, path8: '0', 
            order9: 0, weight9: 10, path9: '0', 
            order10: 0, weight10: 10, path10: '0' })
    }

    const updateRoutine = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!routine) {
            setFormMessage("Please enter a Routine Name")
            return
        }
        const uniquePaths = [...new Set([path1,path2,path3,path4,path5,path6,path7,path8,path9,path10].map(item => item))]; // [ 'A', 'B']
        if(uniquePaths.length <= 1) {
            setFormMessage("Please select at least one Path to use")
            return
        }
        console.table(uniquePaths)
        if(!subpids) {
            setFormMessage("Please enter at least one Subpid")
            return
        }

        setUpdating(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    const pathsFor = (num) => {
        let pathids = []
        switch(num) {
            case 1: pathids = [path2,path3,path4,path5,path6,path7,path8,path9,path10]; break;
            case 2: pathids = [path1,path3,path4,path5,path6,path7,path8,path9,path10]; break;
            case 3: pathids = [path1,path2,path4,path5,path6,path7,path8,path9,path10]; break;
            case 4: pathids = [path1,path2,path3,path5,path6,path7,path8,path9,path10]; break;
            case 5: pathids = [path1,path2,path3,path4,path6,path7,path8,path9,path10]; break;
            case 6: pathids = [path1,path2,path3,path4,path5,path7,path8,path9,path10]; break;
            case 7: pathids = [path1,path2,path3,path4,path5,path6,path8,path9,path10]; break;
            case 8: pathids = [path1,path2,path3,path4,path5,path6,path7,path9,path10]; break;
            case 9: pathids = [path1,path2,path3,path4,path5,path6,path7,path8,path10]; break;
            case 10: pathids = [path1,path2,path3,path4,path5,path6,path7,path8,path9]; break;
            default: return pathOptions;
        }
        return pathOptions.filter(item => !pathids.includes(item.ID));
    }
    
    /*
    const updatePaths = (path, value) => {
        paths[path] = value
        setPaths(paths)
        console.table(paths)
    }
    */

    if(!tool) return <></>
    else if(cascadeError.length > 0) 
        return (
            <>
            <Breadcrumb>
              <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
              <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col md={12}>
                    <Card>
                    <CascadeToolToggle title="Existing Subpids" description="Current Routines being used on Smart Landers" value={cascadeType} onChange={handleChange} />
                        <Row>
                            <Col md={12} className="text-center">{cascadeError}</Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            </>
        )

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item href="/tools/">Tools</Breadcrumb.Item>
          <Breadcrumb.Item active>{tool.tool_name}</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col md={12}>
                <Card>
                <CascadeToolToggle title="Existing Subpids" description="Current Routines being used on Smart Landers" value={cascadeType} onChange={handleChange} />
                    <DataTable tableID={tableID} fieldNames={tableFields} hideTotal={true} dataRows={tableData()} tableSettings={tableTypes.None} onClick={viewRoutine} hover={true} />
                    <Row>
                        <Col md={12} className="text-center"><Button onClick={addRoutine} title="Add New Routines" className="primary" /></Col>
                    </Row>
                </Card>
            </Col>
        </Row>
            <Form title={tool.tool_name} description={tool.description} onSubmit={updateRoutine}> 
        <Row>
            <Col md={6}> 
                <Card.Body>
                    <Row>
                        <Col md={4} className="control-label col-form-label">Routine Name</Col>
                        <Col md={8} sm={8}>
                            <FormControl aria-label="Routine Name" type="text" name="routine" id="routine" value={routine} onChange={handleChange} readOnly={updating} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Subpids</Col>
                        <Col md={8}>
                            <textarea className="form-control" id="subpids" name="subpids" readOnly={updating} style={{height: 300 }} value={subpids} onChange={handleChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>&nbsp;</Col>
                        <Col md={8}><small>The subpids that will use this routine, written on separate lines</small></Col>
                    </Row>

                </Card.Body>
            </Col>
            <Col md={6}> 
                <Card.Body>
                    <Row className="">
                    <Col md={2} className="control-label col-form-label"><strong>Order</strong></Col>
                    <Col md={3} className="control-label col-form-label"><strong>Weight</strong></Col>
                    <Col md={7} className="text-left control-label"><strong>Offer</strong></Col>
                    </Row>

                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order1} name="order1" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight1} placeholder="Weight" name="weight1" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path1} name="path1" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(1)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order2} name="order2" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight2} placeholder="Weight" name="weight2" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path2} name="path2" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(2)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order3} name="order3" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight3} placeholder="Weight" name="weight3" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path3} name="path3" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(3)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order4} name="order4" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight4} placeholder="Weight" name="weight4" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path4} name="path4" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(4)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order5} name="order5" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight5} placeholder="Weight" name="weight5" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path5} name="path5" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(5)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order6} name="order6" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight6} placeholder="Weight" name="weight6" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path6} name="path6" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(6)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order7} name="order7" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight7} placeholder="Weight" name="weight7" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path7} name="path7" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(7)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order8} name="order8" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight8} placeholder="Weight" name="weight8" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path8} name="path8" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(8)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order9} name="order9" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight9} placeholder="Weight" name="weight9" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path9} name="path9" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(9)} useDefault={true} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={2} className="">
                            <Select value={order10} name="order10" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={orderOptions} useDefault={true} />
                        </Col>
                        <Col md={3} className="">
                            <Input type="number" value={weight10} placeholder="Weight" name="weight10" onChange={handleChange} onError={handleError} validate="required|numeric" colSize="12" readOnly={updating} />
                        </Col>
                        <Col md={7} sm={8}>
                        <Select value={path10} name="path10" onChange={handleChange} onError={handleError} validate="required|numeric" colSize={12} readOnly={updating} formValues={pathsFor(10)} useDefault={true} />
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
        <Row>
            <Col md={12} className="text-center pt-5">
                <Card.Text>{formMessage ?? errors ?? ""}</Card.Text>
                {!updating ? <Button title="Update Routine" className="primary" /> : null}
            </Col>
        </Row>
            </Form>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(SubpidTool), "Cascade Subpid Management"));