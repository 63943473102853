import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import pathAPICall from '../../app/apiCalls/pathAPICall'
import { paths_list } from '../../app/apiEndpoints'
import { tableTypes } from '../../app/settings';
import ActiveTableToggle from '../../containers/ActiveTableToggle';

const PathsTable = ({cascadeType, title, description}) => {
    const [data, setData] = useState([])
    const [displayView, setDisplayView] = useState(1)

    const tableID = "path"
    const tableFields = ["ID", "Name", "Type", "Niche", "Active", "Use in Cascade?"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    //const isSysadmin = useSelector((state) => state.user.isSysadmin)

    useMemo(() => {
        const getData = async () => {
            const data = await pathAPICall(paths_list, {'requester': userID, 'company': companyID, 'cascadeType': cascadeType })
            setData(data.paths)
        }
        getData()
    }, [userID, companyID, cascadeType])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            if(displayView === 1 && row.is_active === "0") { return }
            rows.push({'id': row.pathid, 'values': [
                row.pathid,
                row.path,
                row.type === '2' ? 'Affiliate' : 'Fallback',
                row.niche ?? '',
                row.is_active === '1' ? 'Yes' : 'No',
                row.useInAutoFlow === '1' ? 'Yes' : 'No'
                //`${isSysadmin ? `<a href="/landers/${row.lpid}/" class="btn btn-primary btn-sm">Data</a>` : ''}`
            ]});
        });
        return rows
    }

    const filterData = (e) => {
        let { value } = e.target;
        setDisplayView(parseInt(value))
    }

    return (
        <>
            <ActiveTableToggle title={title} description={description} value={displayView} onChange={filterData} />
            <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} />
        </>
    )
}


export default connect(state => ({...state}))(PathsTable)