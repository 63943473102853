
import { FaTachometerAlt, FaUserTie, FaUsers, FaCommentsDollar, FaImage, FaToolbox, FaCode, FaListAlt, FaElementor, FaArrowUp, FaShieldAlt, FaCloudUploadAlt } from "react-icons/fa";
//FaListAlt, FaToolbox, FaSlidersH
export const sideMenuLinks = [
    {
      path: "/dashboard/",
      name: "Dashboards",
      icon: FaTachometerAlt,
      reqAdmin: false,
      reqSysAdmin: false,
      reqGroup: ['1','2','3']
    },
    {
      path: "/mediabuy/",
      name: "Media Buying",
      icon: FaCommentsDollar,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/reports/",
      name: "Reports",
      icon: FaListAlt,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: ['4']
    },
    {
      path: "/tools/",
      name: "Tools",
      icon: FaToolbox,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },  
    {
      path: "/paths",
      name: "Paths",
      icon: FaArrowUp,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/landers",
      name: "Landing Pages",
      icon: FaElementor,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/api/",
      name: "API",
      icon: FaCloudUploadAlt,
      reqAdmin: false,
      reqSysAdmin: true
    },
    {
      path: "/banners/",
      name: "Banners",
      icon: FaImage,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/xml/",
      name: "XML",
      icon: FaCode,
      reqAdmin: false,
      reqSysAdmin: true
    },
    {
      path: "/privacy/",
      name: "Privacy",
      icon: FaShieldAlt,
      reqAdmin: false,
      reqSysAdmin: true
    },
    {
      path: "/clients/",
      name: "Clients",
      icon: FaUserTie,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/users/",
      name: "Users",
      icon: FaUsers,
      reqAdmin: true,
      reqSysAdmin: true,
      reqGroup: []
    }
    /*,
    {
      path: "/admin/",
      name: "Admin",
      icon: FaSlidersH,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    }
      */
  ];