import React from 'react';

import './print.css';

export class APIDocumentationView extends React.PureComponent {

    render() {
        const API = this.props.API
        const firstOfferID = API.offers && API.offers.length > 0 ? API.offers[0].offer_id : "";
        return (
            <>
    <div className="container">
        <h1 className="mb-4">FS-API Documentation</h1>
        
        <div className="alert alert-info">
            <strong>Base URL:</strong> <code>https://fs-api.com/api/user</code>
        </div>

        <h2>Endpoint Details</h2>
        <table className="table table-bordered">
            <tbody>
            <tr><th>Method</th><td><code>POST</code></td></tr>
            <tr><th>URL</th><td><code>https://fs-api.com/api/user</code></td></tr>
            <tr><th>Headers</th><td>
                <ul>
                    <li><strong>Authorization</strong>: {API.api_key} (Required)</li>
                    <li><strong>Content-Type</strong>: <code>application/x-www-form-urlencoded</code> (Required)</li>
                </ul>
            </td></tr>
            </tbody>
        </table>

        <h2>Offer Details</h2>
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Offer</th><th>OfferID</th>
                </tr>
            </thead>
            <tbody>
            {API.offers && API.offers.map((row) => ( 
                <>
                    <tr><td>{row.offer_name}</td><td>{row.offer_id}</td></tr>
                </>)
            )}
            </tbody>
        </table>

        <h2>Required Fields</h2>
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Field</th><th>Type</th><th>Description</th><th>Example</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>offerId</td><td>Integer</td><td>Valid offer ID</td><td><code>{firstOfferID}</code></td></tr>
                <tr><td>email</td><td>String</td><td>User's email</td><td><code>john.doe@example.com</code></td></tr>
                <tr><td>username</td><td>String</td><td>Unique username</td><td><code>JohnDoe</code></td></tr>
                <tr><td>password</td><td>String</td><td>6-20 characters</td><td><code>securePass123</code></td></tr>
                <tr><td>ip</td><td>String</td><td>User's IP address</td><td><code>203.0.113.10</code></td></tr>
                <tr><td>gender</td><td>String</td><td>male, female, or trans</td><td><code>male</code></td></tr>
                <tr><td>birthdate</td><td>String</td><td>YYYY-MM-DD format</td><td><code>1990-01-01</code></td></tr>
                <tr><td>userAgent</td><td>String</td><td>Browser User-Agent</td><td><code>Mozilla/5.0</code></td></tr>
                <tr><td>country</td><td>String</td><td>2-letter country code</td><td><code>US</code></td></tr>
            </tbody>
        </table>

        <h2>Optional Fields</h2>
        <table className="table table-bordered">
            <thead>
                <tr><th>Field</th><th>Type</th><th>Description</th><th>Example</th></tr>
            </thead>
            <tbody>
                <tr><td>lookingFor</td><td>String</td><td>male, female, trans</td><td><code>female</code></td></tr>
                <tr><td>city</td><td>String</td><td>City name</td><td><code>New York</code></td></tr>
                <tr><td>zip</td><td>String</td><td>Postal/ZIP code</td><td><code>10001</code></td></tr>
                <tr><td>subid</td><td>String</td><td>Your custom traffic source tracking id</td><td><code>80231d4c-fa71-6fee-gre1-0c6fle3220215</code></td></tr>
                <tr><td>cid</td><td>String</td><td>Your custom traffic identifier</td><td><code>kjwe213jh234kb</code></td></tr>
            </tbody>
        </table>

        <h2>Example cURL Request (Command Line)</h2>
        <pre><code>curl -X POST https://fs-api.com/api/user \
-H "Authorization: {API.api_key}" \
-H "Content-Type: application/x-www-form-urlencoded" \
-d "email=john.doe@example.com&username=JohnDoe&password=securePass123&ip=203.0.113.10&gender=male&birthdate=1990-01-01&userAgent=Mozilla/5.0&country=US&offerId={firstOfferID}&city=NewYork&zip=10001"</code></pre>

        <h2>Example PHP Request (cURL)</h2>
        <pre><code>{`<?php
$apiUrl = 'https://fs-api.com/api/user';
$apiKey = '${API.api_key}';

$postData = [
    'email' => 'john.doe@example.com',
    'username' => 'JohnDoe',
    'password' => 'securePass123',
    'ip' => '203.0.113.10',
    'gender' => 'male',
    'birthdate' => '1990-01-01',
    'userAgent' => $_SERVER['HTTP_USER_AGENT'],
    'country' => 'US',
    'offerId' => ${firstOfferID},
    'subid' => '80231d4c-fa71-6fee-gre1-0c6fle3220215',
    'cid' => 'kjwe213jh234kb'
];

$ch = curl_init($apiUrl);
curl_setopt($ch, CURLOPT_HTTPHEADER, [
    'Authorization: ' . $apiKey,
    'Content-Type: application/x-www-form-urlencoded'
]);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, http_build_query($postData));
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

$response = curl_exec($ch);
$httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);
curl_close($ch);

if ($httpCode === 200) {
    echo "Success: $response";
} else {
    echo "Error ($httpCode): $response";
}
?>`}</code></pre>

        <h2>Example Success Response</h2>
        <pre><code>{`{
    "message": "User data received successfully.",
    "redirectUrl":"https://fs-api.com/api/go?ref=sdjh23ljrhjklhdlksdjhfskljfdsklkldsj9i398=",
    "success": 1
}`}</code></pre>

        <h2>Possible Error Responses</h2>
        <pre><code>{`{
    "success": 0,
    "error": "Validation failed.",
    "details": ["Invalid or missing email.", "Password must be between 6 and 20 characters."]
}`}</code></pre>

        <footer className="mt-5 text-muted">
            <p><strong>Last Updated:</strong> March 22, 2025</p>
        </footer>
    </div>

            </>
        )
    }
}