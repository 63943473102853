import React from 'react';

import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import CardHeader from '../../containers/CardHeader';
import CardSectionTitle from '../../containers/CardSectionTitle';
import Button from '../../containers/Form/Button';

import reportAPICall from '../../app/apiCalls/reportAPICall'
import { report_details } from "../../app/apiEndpoints"

import generateReport from "../../app/functions/generateReport"
import { CSVLink } from "react-csv";

import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, Row, Col, Accordion, FormGroup, Form } from 'react-bootstrap';

const ReportDetails = (props) => {
    const [report, setReport] = useState({})
    const reportID = useSelector((state) => state.currentReportID)
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [startDate, setStartDate] = useState(new Date(Moment().add(-1, 'months').format('MM/DD/YYYY')))
    const [endDate, setEndDate] = useState(new Date(Moment().format('MM/DD/YYYY')))
    const [source, setSource] = useState(1)
    const [cascadeType, setCascadeType] = useState(0)

    const [csv, setCsv] = useState({})
    const [genStatus, setGenStatus] = useState(0)

    const cascadeReports = [25,26,30,35]

    useMemo(() => {
        const getDetails = async () => {
            if(reportID > 0) {
                const data = await reportAPICall(report_details, {'reportID': reportID, 'requester': userID, 'company': companyID })
                setReport(data.report)
            }
        }
        getDetails()
    }, [reportID, userID, companyID])
    
    const exportReport = async (e) => {
        e.preventDefault()
        setGenStatus(1)

        const csvData = await generateReport(report, Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), source, cascadeType)
        if(csvData.file?.length > 0) {
            setCsv(csvData)
            setGenStatus(2)
        }
    }

    const resetReport = (e) => {
        e.preventDefault()
        setGenStatus(0)
        setCsv({})
    }

    if(!reportID) return <></>

    return (
        <Card>
            <CardHeader title={report.report_name} description={report.company_name} />
            <Card.Body>
                {report.description} 
            </Card.Body>
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="Create Report" />
                        <form className="form-horizontal" id="reportForm" method="GET" action={`/reports/view/${report.uri}`} >
                            <br/>
                            { (parseInt(report.report_id) === 2 || parseInt(report.report_id) === 10) &&
                            <FormGroup>
                                <Row>
                                    <Form.Label column md="3">Source</Form.Label>
                                    <Col md={9}>
                                        <select id="source" name="source" className="form-control" value={source} readOnly={genStatus > 0} onChange={(e) => setSource(e.target.value)}>
                                            <option value="1">Banners</option>
                                            <option value="2">Popups</option>
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup> }
                            { (parseInt(report.report_id) === 9 || parseInt(report.report_id) === 13) &&
                            <FormGroup>
                                <Row>
                                    <Form.Label column md="3">Group By</Form.Label>
                                    <Col md={9}>
                                        <select id="source" name="source" className="form-control" value={source} readOnly={genStatus > 0} onChange={(e) => setSource(e.target.value)}>
                                            <option value="1">Campaign</option>
                                            <option value="2">Campaign - Banner</option>
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup> }
                            { cascadeReports.includes(parseInt(report.report_id)) &&
                            <FormGroup>
                                <Row>
                                    <Form.Label column md="3">Cascade Type</Form.Label>
                                    <Col md={6}>
                                        <select id="cascadeType" name="cascadeType" className="form-control" value={cascadeType} readOnly={genStatus > 0} onChange={(e) => setCascadeType(e.target.value)}>
                                            <option value="1">Straight</option>
                                            <option value="2">Trans</option>
                                            <option value="6">Gay</option>
                                            <option value="3">Cams</option>
                                            <option value="4">Trans Cams</option>
                                            <option value="5">Blacklist</option>
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup> }
                            {parseInt(report.has_date_range) === 1 ?
                            <>
                            <FormGroup>
                                <Row>
                                    <Form.Label column md="3">Start Date</Form.Label>
                                    <Col md={9}>
                                        <DatePicker id="startDate" name="startDate" selected={startDate} readOnly={genStatus > 0} className="form-control" onChange={(date) => setStartDate(date)} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Form.Label column md="3">End Date</Form.Label>
                                    <Col md={9}>
                                        <DatePicker id="endDate" name="endDate" selected={endDate} readOnly={genStatus > 0} className="form-control" onChange={(date) => setEndDate(date)} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            </> : null}
                            <Card.Text className="text-right">
                            {genStatus === 0 && 
                                <Button title="View Report" className="primary" /> } { }
                            { genStatus === 0 && // default
                                <Button title="Export Report" className="secondary" onClick={exportReport} /> }
                            { genStatus === 1 && // generating has begun
                                <small>Generating Report...</small> }
                            { genStatus === 2 && csv.file && // download now available
                                <CSVLink 
                                data={csv.data}
                                filename={csv.file}
                                className="btn btn-success"
                                target="_blank">Download Report</CSVLink> } { }
                            {genStatus === 2 && 
                                <Button title="Reset" className="secondary" onClick={resetReport} /> }
                            </Card.Text>
                        </form>
                    </Card>
                </Accordion>
            </Card.Body>
        </Card>
    )
}

export default connect(state => ({...state}))(ReportDetails)