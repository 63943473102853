import React, { useMemo, useState } from 'react';

import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import { Breadcrumb, Card } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'
import pathAPICall from '../../app/apiCalls/pathAPICall';
import { paths_list } from '../../app/apiEndpoints';
import DataTable from '../../containers/DataTable';
import { tableTypes } from '../../app/settings';

const PathsSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    const [data, setData] = useState([])

    const tableID = "path"
    const tableFields = ["Path Type", "# of Paths"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    //const isSysadmin = useSelector((state) => state.user.isSysadmin)

    useMemo(() => {
        const getData = async () => {
            const data = await pathAPICall(paths_list, {'requester': userID, 'company': companyID, 'cascadeType': "" })
            setData(data.paths)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach((row) => {
            rows.push({'id': row.link, 'values': [
                `<a href="/paths/${row.link}/">${row.name}</a>`,
                row.count ?? "0"
            ]});
        });
        return rows
    }

    if(!isSysadmin) return <InvalidPermissions />

    return (
        <>
        <Breadcrumb>
          <Breadcrumb.Item active>Paths</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
            <CardHeader title="Paths" description="Listing of Path Types" />
            <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} totals={false} tableSettings={tableTypes.None} />
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(PathsSection), "Paths"));
